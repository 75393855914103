import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { navigate } from "gatsby"

const Redirect = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const { redirectTo } = frontmatter

  useEffect(() => {
    navigate(`/${redirectTo}`, { replace: true })
  })

  return null
}

export default Redirect

export const RedirectQuery = graphql`
  query Redirect($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        redirectTo
      }
    }
  }
`
